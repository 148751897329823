body {
   /* background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);  */
  background: #360033;  
  background: -webkit-linear-gradient(to right, #0b8793, #360033); 
  background: linear-gradient(to right, #0b8793, #360033); 
   
  font-family: "Oswald", sans-serif;
}



.App {
  width: 90%;
  margin: 0 auto;
}
